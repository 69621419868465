import React, { useEffect, useState } from "react";
import Loader from "components/loader";
import formCommonPostData from "utils/form-common-post-data";
import Reanalysisicon from "assets/reanalysis_icon.svg";
import { Link, navigate } from "gatsby";
import { useTopicIdentifier } from "hooks/Forms/useTopicIdentifier";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import { fileExtension } from "utils/fileextension";
import FileLoadingInput from "components/FileUploading/fileloadinginput";
import ReUploadFile from "components/FileUploading/reUploadingfile";
import UnsupportedFile from "components/FileUploading/unsupportedfile";
import UploadIcon from "assets/uploadIcon.svg";
import useDebounce from "components/QualityTools/useDebounce";
import detectLanguage from "utils/detectLanguage";
import { trySampleText } from "utils/sampletext-generator";
import useAutoFocus from "hooks/useAutoFocus";
import { Tooltip } from "react-tooltip";
import CopyIcon from "assets/copytools.jpg";
import { useLocation } from "@reach/router";
import CopyLink from "assets/copu_link_icon.svg";

const TopicdetectorTool = () => {
  const { status, mutateAsync } = useTopicIdentifier();
  const [detectedTopicText, setDetectedTopicText] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [inputText, setInputText] = useState("");
  const [error, setError] = useState(false);
  const [fileUploadLoading, setFileUploading] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [sizeLimitError, setSizeLImitError] = useState(false);
  const [file, setFile] = useState(false);
  const [inputLang, setInputLang] = useState("");
  const [textLoader, setTextLoader] = useState(false);
  const [copySuccess, setCopySuccess] = useState("Copy");
  const debouncedInputText = useDebounce(inputText, 3000);
  const [isEnterText, setIsEnterText] = useState(true);
  const [tooltipContent, setTooltipContent] = useState("Copy");
  const [urlText, setUrlText] = useState("");
  const [shareId, setShareId] = useState("");
  const location = useLocation();
  const url = location.href;

  useAutoFocus("textarea");
  useEffect(() => {
    if (debouncedInputText?.trim()?.length >= 3) {
      detectLanguageForSource(debouncedInputText);
    }
  }, [debouncedInputText]);
  const detectLanguageForSource = async (text) => {
    const { languageCode } = await detectLanguage(text);
    if (languageCode) {
      setInputLang(languageCode);
    }
  };

  const handleSubmit = async () => {
    // setTextLoader(true)
    setError("");
    // if (inputText.trim() === "") {
    //   alert("Please enter text in the textarea box");
    //   return false;
    // }
    let postData;
    if (urlText) {
      postData = {
        tool_name: "identifyTopic",
        user_url: urlText,
      };
    } else {
      postData = {
        tool_name: "identifyTopic",
        user_text: inputText,
        source_language_code: inputLang,
        ...formCommonPostData(),
      };
    }

    try {
      const res = await mutateAsync(postData);
      const newShareId = res?.meta?.share_id;
      setShareId(newShareId);
      const response = res.result;
      let parsedResponse;
      if (typeof response === "string") {
        parsedResponse = JSON.parse(response?.replace(/,\s*}/, "}"));
      } else {
        parsedResponse = response;
      }

      const domain = parsedResponse?.domain || parsedResponse?.Domain;

      const specialization =
        parsedResponse?.Specialization || parsedResponse?.specialization;

      if (domain && specialization) {
        setDetectedTopicText(`${domain} (${specialization})`);
      } else if (domain) {
        setDetectedTopicText(domain);
      } else {
        setDetectedTopicText("");
      }
      if (newShareId === undefined && !res.success) {
        setError("Unable to generate a response. Please try again.");
        return;
      }
      // navigate(`/tools/topic-detection/result?share-id=${newShareId}`);
    } catch (error) {
      setError(error);
    } finally {
    }
  };

  const handleTextChange = (e) => {
    const text = e.target.value;
    setInputText(text);
  };
  useEffect(() => {
    const words = inputText.split(/\s+/).filter((word) => word.length > 0);
    setWordCount(words.length);
  }, [inputText]);

  const uploadFile = async (e) => {
    setSizeLImitError(false);
    setUploadError(false);
    const selectedFile = e.target.files[0];
    const extension = fileExtension(selectedFile.name);

    // if (extension) return;
    // setFile(selectedFile);
    if (!extension) {
      setUploadError(true);
      setFile(selectedFile);
      return;
    }
    setFile(selectedFile);
    if (selectedFile.size >= 15000000) {
      alert("File is too large. Please upload file less than 10 MB.");
      setSizeLImitError(true);
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      setFileUploading(true);
      setUploadError(null);
      const res = await http().post(endpoints.forms.toolsFileUpload, formData);

      setInputText(res.data.text);
    } catch (err) {
      console.log(err, "error");
      setUploadError("Error uploading file. Please try again.");
    } finally {
      setFileUploading(false);
    }
  };
  const handleCopy = () => {
    const textToCopy = document.getElementById("output").innerText;
    navigator.clipboard.writeText(textToCopy);
    setCopySuccess("Copied");
    setTimeout(() => {
      setCopySuccess("Copy");
    }, 1500);
  };
  const clearText = () => {
    setWordCount(0);
    setDetectedTopicText("");
    setInputText("");
    setInputLang("");
    setTextLoader(false);
  };

  const toggleInput = (isTextInput) => {
    setIsEnterText(isTextInput);
    setUrlText("");
    setInputText("");
  };

  const handleLinkChange = (e) => {
    const text = e.target.value;
    setUrlText(text);

  };

  const clearLink = () => {
    setUrlText("");
    setDetectedTopicText("");
    setInputText("");

  };
  const shareresult = () => {
    navigator.clipboard.writeText(`${url}/result?share-id=${shareId}`);
    setTooltipContent("Link copied");

    setTimeout(() => {
      setTooltipContent("Copy");
    }, 2000);
  };
  return (
    <div className="bg-[#F5F5F5] ">
      <div className=" w-full  py-7 ">
        <ToolsBreadcrumb />
      </div>

      <div className="py-12 md:py-24 max-w-7xl mx-auto px-4 grid lg:grid-cols-2 gap-12">
        <div>
          <h1 className="text-[#5B93FF] text-center md:text-start text-base font-primary font-semibold uppercase">
            TOPIC DETECTION TOOL
          </h1>
          <h2 className="text-[#0A2641] pt-3 text-center md:text-start font-bold text-[28px] lg:text-[40px] leading-[48px] font-primary">
            Match Texts with Specialized Translators for Enhanced Quality
          </h2>
          <p className="hidden md:block text-[#424242] text-center md:text-start text-base md:text-lg font-opensans leading-[26px] pt-6">
            Enter your text in any language, and the topic identifier tool will
            automatically detect the main domain, whether it is legal, medical,
            marketing, or any other subject field.
          </p>
        </div>
        <div>
          <div className=" shadow-lg rounded-lg bg-white pt-4">
            <div className="flex gap-3 ml-4">
              <button
                onClick={() => toggleInput(true)}
                className={`py-1 px-3 rounded-md font-semibold ${
                  isEnterText
                    ? "text-white bg-[#7AA7FF]"
                    : "text-[#7AA7FF] bg-[#F0F5FF]"
                }`}
              >
                Enter Text
              </button>
              <button
                onClick={() => toggleInput(false)}
                className={`py-1 px-3 rounded-md font-semibold ${
                  isEnterText
                    ? "text-[#7AA7FF] bg-[#F0F5FF]"
                    : "text-white bg-[#7AA7FF]"
                }`}
              >
                Enter URL
              </button>
            </div>
            {isEnterText ? (
              <div className="relative">
                {fileUploadLoading ? (
                  <FileLoadingInput file={file} />
                ) : sizeLimitError && !fileUploadLoading ? (
                  <ReUploadFile
                    file={file}
                    setFile={setFile}
                    setSizeLImitError={setSizeLImitError}
                    uploadFile={uploadFile}
                  />
                ) : uploadError && !fileUploadLoading ? (
                  <UnsupportedFile
                    file={file}
                    setSizeLImitError={setSizeLImitError}
                    setFile={setFile}
                    uploadFile={uploadFile}
                    setFileUploadError={setUploadError}
                  />
                ) : (
                  <>
                    <textarea
                      autoFocus
                      dir={
                        inputLang === "he" || inputLang === "ar" ? "rtl" : "ltr"
                      }
                      placeholder={textLoader ? "" : "Type your text here or"}
                      name="message"
                      id="message"
                      className={`anonymize_tool  ${
                        inputLang === "he" || inputLang === "ar"
                          ? "pl-[40px] pr-5"
                          : "pl-5 pr-[40px]"
                      } py-5 min-h-[240px] placeholder-[#B4B4B4] text-black font-primary bg-transparent text-[15px] overscroll-y-none resize-none border-none outline-none block w-full`}
                      onChange={handleTextChange}
                      value={inputText}
                      dangerouslySetInnerHTML={{
                        __html: inputText.replace(/\n/g, "<br />"),
                      }}
                      readOnly={textLoader}
                    />
                    {textLoader && (
                      <div className="absolute top-[11px] left-[14px] text-[#B4B4B4] italic">
                        Adding sample text...
                      </div>
                    )}
                    {!inputText && !textLoader && (
                      <button
                        className="absolute top-[18px] left-[182px] underline italic text-[#5B93FF] cursor-pointer pointer-events-auto"
                        onClick={() =>
                          trySampleText(setInputText, setTextLoader)
                        }
                      >
                        Try Sample Text
                      </button>
                    )}
                  </>
                )}

                {wordCount > 0 && (
                  <p
                    className={`absolute ${
                      inputLang === "he" || inputLang === "ar"
                        ? "left-[17px]"
                        : "right-[17px]"
                    } top-[14px]  w-max cursor-pointer`}
                    onClick={clearText}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M19.5787 17.5581C19.8469 17.8263 19.9977 18.1902 19.9977 18.5696C19.9977 18.9489 19.8469 19.3128 19.5787 19.581C19.3104 19.8493 18.9466 20 18.5672 20C18.1878 20 17.824 19.8493 17.5557 19.581L10.0006 12.0235L2.44313 19.5787C2.17487 19.8469 1.81104 19.9976 1.43166 19.9976C1.05228 19.9976 0.688446 19.8469 0.420187 19.5787C0.151927 19.3104 0.00122071 18.9466 0.0012207 18.5672C0.0012207 18.1878 0.151927 17.824 0.420187 17.5557L7.97768 10.0006L0.422566 2.4431C0.154307 2.17484 0.00360069 1.81101 0.0036007 1.43163C0.0036007 1.05225 0.154307 0.688415 0.422566 0.420156C0.690826 0.151897 1.05466 0.00118977 1.43404 0.00118977C1.81342 0.00118977 2.17725 0.151897 2.44551 0.420156L10.0006 7.97765L17.5581 0.418966C17.8264 0.150706 18.1902 -6.32041e-09 18.5696 0C18.949 6.32041e-09 19.3128 0.150706 19.5811 0.418966C19.8493 0.687225 20 1.05106 20 1.43044C20 1.80982 19.8493 2.17365 19.5811 2.44191L12.0236 10.0006L19.5787 17.5581Z"
                        fill="#69AAE8"
                      />
                    </svg>
                    {/* &#10005; */}
                  </p>
                )}

                <div className="flex justify-between items-center p-4">
                  {inputText ? (
                    <p className="text-right mt-2 text-sm text-[#9C9C9C]">
                      word count: {wordCount}
                    </p>
                  ) : (
                    <>
                      <label
                        htmlFor="uploadFile"
                        className="flex gap-2 cursor-pointer"
                      >
                        <img src={UploadIcon} alt="upload_icon" />
                        <span>Upload File</span>
                      </label>
                      <input
                        type="file"
                        className="hidden"
                        id="uploadFile"
                        onChange={uploadFile}
                      />
                    </>
                  )}
                  <button
                    onClick={handleSubmit}
                    disabled={!inputText || !inputLang}
                    className={` ${
                      !inputText || !inputLang
                        ? "bg-[#E8E8E8] text-[#858585] cursor-not-allowed "
                        : "bg-lightBlue text-white"
                    } text-base font-semibold  font-opensans text-center rounded-md block px-6 py-2`}
                  >
                    Identify topic
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <textarea
                  name="url"
                  value={urlText}
                  placeholder="Paste your link here"
                  className={`min-h-[250px]  font-opensans placeholder-[#858585] text-[#1463FF] leading-[26px] py-3 px-3 bg-transparent text-lg overscroll-y-none resize-none border-none outline-none block w-full`}
                  onChange={handleLinkChange}
                  readOnly={textLoader || detectedTopicText}
                />
                <div className="flex justify-between items-center p-4 mt-8">
                  <span></span>
                  <div className="flex gap-2">
                    <button
                      type="button"
                      className={` ${
                        urlText.length > 0
                          ? "text-[#0A2641] border border-[#0A2641] cursor-pointer"
                          : "border border-[#B0B0B0] text-[#B0B0B0]"
                      } flex gap-2 bg-white px-4 py-2 rounded-md`}
                      onClick={clearLink}
                      disabled={urlText.length === 0}
                    >
                      Clear link
                    </button>
                    <button
                      type="button"
                      onClick={handleSubmit}
                      disabled={!urlText}
                      className={`bg-[#5B93FF] ${
                        !urlText ? " cursor-not-allowed" : ""
                      } text-white px-4 py-2 rounded-md`}
                    >
                      {"Identify link"}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="mt-4 rounded-lg bg-white w-full md:flex justify-between items-center px-4 py-6 shadow-lg">
            <div>
            <p className="text-[#858585] font-opensans text-semibold leading-6">
              Domain:
            </p>
            {detectedTopicText && (
            <p className="flex items-center gap-2 w-max">
                      <span
                        id="output"
                        className="text-[#27862A] text-lg font-bold leading-6 font-opensans"
                      >
                        {detectedTopicText}
                      </span>
                     
                    </p>
                    )}
                     { error && (
                  <span className="text-[#ff0000] text-lg font-bold leading-6 font-opensans">
                    {error.message}
                  </span>
                ) }
                    </div>
                  

            {status === "loading" ? (
              <p className="text-[#858585] mt-4 md:mt-0 font-opensans text-semibold leading-6 flex">
                Loading...
                <Loader />
              </p>
            ) : (
              <p className="text-[#858585] mt-4 md:mt-0 font-opensans text-semibold leading-6">
                {detectedTopicText ? (
                  <div className="flex gap-4 items-center">
                     <button
                        data-tooltip-id="copy-tooltip"
                        data-tooltip-content={copySuccess}
                        onClick={handleCopy}
                        className="w-max"
                      >
                        <img src={CopyIcon} alt="/copyicons" />
                      </button>
                    <Tooltip
                      id="copy-tooltip"
                      place="top"
                      multiline={true}
                      className="z-50"
                    />
                    {detectedTopicText && (
                     
                        <button
                          className="flex items-center gap-2 w-max"
                          data-tooltip-id="linkcopy-tooltip"
                          data-tooltip-content={tooltipContent}
                          onClick={shareresult}
                        >
                          <img src={CopyLink} alt="copy_link" />
                         
                        </button>
                    
                    )}
                  </div>
                ) : (
                  "--"
                )}
              </p>
            )}
          </div>

          <Tooltip
            id="linkcopy-tooltip"
            place="top"
            multiline={true}
            className="z-50"
          />
          <p className="w-full text-center text-base mt-12 pb-4 text-[#9C9C9C]">
            Assisted by AI tools. Inaccuracies may occur.
          </p>
        </div>
        <p className="block md:hidden text-[#424242] text-center md:text-start text-base md:text-lg font-opensans leading-[26px] mt-8">
          Enter your text in any language, and the topic identifier tool will
          automatically detect the main domain, whether it is legal, medical,
          marketing, or any other subject field.
        </p>
      </div>
    </div>
  );
};

export default TopicdetectorTool;
